import React from "react"
import { Section } from "elements-ui"
import { SEO } from "@maker-ui/seo"

import ServiceHeader from "../components/ServiceHeader"
import Certifications from "../components/Certifications"
import Raves from "../components/Raves"
import PortableText from "../components/PortableText"

export default ({ data }) => {
  const {
    _rawBody,
    name,
    subtitle,
    raves,
    seo,
    certifications,
  } = data.allSanityService.nodes[0]

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <ServiceHeader>{name}</ServiceHeader>
      <Section sx={{ variant: "pageBody" }}>
        <h2>{subtitle}</h2>
        <PortableText blocks={_rawBody} />
      </Section>
      <Raves raves={raves} service="Leadership & Training" />
      <Certifications certs={certifications} />
    </>
  )
}

export const query = graphql`
  query {
    allSanityService(filter: { slug: { eq: "leadership" } }) {
      nodes {
        ...ServiceFragment
      }
    }
  }
`
